/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";

body {
    margin: 0px;
    font-family: "Porsche Next", "Arial Narrow", Arial, sans-serif !important;
}

.form-row-spacing {
    margin-top: 1rem;
}

.form-section-spacing {
    margin-top: 20px;
}

.m-top2 {
    margin-top: 2rem;
    ;
}

.red-text {
    color: #f00;
}

.text-success {
    color: #197E10;
    font-weight: 600;
}

.text-normal {
    color: #000;
    font-weight: 600;
}

.text-center {
    text-align: center;
}

.label__text {
    font-size: .85rem;
}

.table-block {
    border-radius: 10px;
    padding: 15px 30px;
    margin: 25px 0px;
    border: 1px solid #D8D8DB;
}

.password-error-block {
    border-radius: 3px;
    border: 2px solid #585A5D;
    width: 100%;
    margin: 10px 0px;
    padding: 20px;
}

.search-block {
    width: 100%;
    /* max-width: 100%; */
    display: flex;
    justify-content: end;

    .search-box {
        width: calc(100% - 150px);
        max-width: 400px;
    }
}

.add-title {
    margin: 25px 0px;
}

.page-title {
    padding-top: 15px;

    .title-text {
        display: inline-block;
        padding: 10px 0px;
    }
}

.add-button {
    float: right;
    margin-left: 20px;
    //   margin-top: -10px;
}

.btn-box p-button {
    margin-right: 15px;
}

.form-block {
    margin: 30px 0px;
    min-height: 100px;
}

.loading-block {
    text-align: center;
    margin: 10px 0px;
}

.disable-all:not(.gallery) {
    pointer-events: none;
    opacity: 0.6;

    * {
        pointer-events: none;
    }

    .mat-mdc-form-field-icon-suffix {
        display: none;
    }
}

.disable-all-full:not(.gallery) {
    pointer-events: none;
    opacity: 0;
}

.loader-box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
}

.loading {
    height: 0;
    width: 0;
    padding: 30px;
    border: 2px solid #cdcecf;
    border-right-color: #34383b;
    border-radius: 50%;
    -webkit-animation: rotate 1s infinite linear;
    /* left, top and position just for the demo! */
    position: absolute;
    left: 50%;
    top: 50%;
}

.inline_ul {
    margin: 0px;
    padding: 0px;

    li {
        list-style-type: none;
        display: inline-block;
        height: 30px;
        padding: 10px 20px 10px 0px;
        vertical-align: top;
    }
}

@keyframes rotate {

    /* 100% keyframe for  clockwise. 
       use 0% instead for anticlockwise */
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mdc-notched-outline {
    border: 1px solid #333;
    border-radius: 7px;
}

.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.theme-dark-contrast-high {
    background: #eeeff2;
    border-radius: 10px;
    margin-top: 20px;
}

.bold {
    font-weight: bold;
}

.header p {
    font-size: 20px;
}

@media screen and (max-width:900px) {
    .search-block .search-box {
        width: calc(100% - 168px);
        max-width: none;

        &.full {
            width: 100%;
            margin-top: 10px;
        }
    }

    .search-block {
        justify-content: end;
    }

}

@media screen and (max-width:480px) {
    .search-block .search-box {
        width: 100%
    }

    .search-block {
        display: block;
    }

    .add-button {
        margin-top: 15px;
    }
}

.weight {
    font-weight: bold;
}

.disable-all-form {
    .default-disable{
        opacity: 1;
    }
    button {
        pointer-events: none;
        opacity: .6;;
    }

    form {
        pointer-events: none;
        opacity: .6;
        
    }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.disable-all .disable-all{
    opacity: 1;
}
p-table-cell{
    max-width: 300px;
}
/* .mdc-button__label{
    span{
        display: none;
    }
} */